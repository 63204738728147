<template>
  <div style="width: 100vw;height: 100vh;background: #003262;">

    <div
        class="boxStyle">

        <div class="loginView"> 
            <img class="loginLogoStyle" src="../../assets/loginLogo.png">


            <input class="login-input" placeholder="Email" v-model="form.account_name"/>
            <input class="login-input1" show-password="false" type="password" placeholder="Password" v-model="form.account_pwd"/>

            <div class="login-input2" @click="submit">
              Login

            </div>

        </div>

      
      

    </div>
    <div v-show="!isChrome" class="navigator-tip">ALERT: you are NOT using Google Chrome to visit this site, which might cause unexpected misfunctions or bugs. The developer of this system requires visiting via Chrome and will not be responsible for any errors on non-Chrome platforms.</div>
  </div>
</template>

<script>
import {loginByEducator, loginByStudent, loginByEmployee} from '../../api/index'
import {
  setUserId,
  setUserName,
  setUserType,
  getProjectCode,
  setApplicationExtraEvents,
  setAppId,
    setAccountName,
  setApplicationEvents, setAvatarUrl,logout,
  getBrowserType
} from '../../utils/store'
import '../../assets/common/font.css'
export default {
  name: 'Login',
  data() {
    return {
      form: {
        account_name: '',
        account_pwd: '',
        type: '学生'
      },
      isChrome:false
    }
  },
  mounted() {
    //先清除一下数据
    logout()

    // this.$nextTick(() => {
    //   // 禁用右键
    //   document.oncontextmenu = new Function("event.returnValue=false");
    //   // 禁用选择
    //   document.onselectstart = new Function("event.returnValue=false");
    // });
    let browser = getBrowserType()
    if(browser==='CHROME'){
        this.isChrome = true
    }
  },
  methods: {
    submit() {
      if (!this.form.account_name) {
        this.$message.error('Please input email')
        return
      }

      if (!this.form.account_pwd) {
        this.$message.error('Please input password')
        return
      }

      if (this.form.type === '讲师') {
        loginByEducator(this.form.account_name, this.form.account_pwd).then(res => {
          if (res.data.code == 0) {
            this.$message({
              message: 'login success',
              type: 'success'
            })
            setUserId(res.data.data._id.$id)
            setUserName(res.data.data.educator_first_name + ' ' + res.data.data.educator_last_name)
            setUserType('educator')
            this.$router.push('/teacherlist')
          } else {
            this.$message.warning('login fail')
          }
        })
      } else if (this.form.type === '学生') {
        loginByStudent(getProjectCode(), this.form.account_name, this.form.account_pwd ,'BerkeleyFBL').then(res => {
          if (res.data.code == 0) {
            this.$message({
              message: 'login success',
              type: 'success'
            })
            var result = res.data.data
            var application_extra_events= result.application_extra_events
            setApplicationExtraEvents(application_extra_events)
            var application_events= result.application_events
            setApplicationEvents(application_events)
            var app_id = result._id.$id
            setAppId(app_id)
            var account_name = result['student_info']['account_name']
            setAccountName(account_name)
            var avatar_url = result['student_info']['avatar_url']
            setAvatarUrl(avatar_url)
            setUserId(res.data.data.student_id)
            setUserType('student')
            var student_name = res.data.data.student_info.student_lastName + res.data.data.student_info.student_givenName
            setUserName(student_name)
            this.$router.push('/home')
          } else {
            this.$message.warning('Login failed: ' + res.data.msg)
          }
        })
      } else if (this.form.type === '助教') {
        loginByEmployee(this.form.account_name, this.form.account_pwd).then(res => {
          if (res.data.code == 0) {
            this.$message({
              message: 'login success',
              type: 'success'
            })
            setUserId(res.data.data._id.$id)
            setUserType('employee')
            setUserName(res.data.data.user_lastName + res.data.data.user_givenName)
            this.$router.push('/employeelist')
          } else {
            this.$message.warning('login fail')
          }
        })
      }

    }
  }
}
</script>

<style lang="less">
.navigator-tip{
    position: absolute;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    bottom: 0;
    color: white;
    font-size: 14px;
    text-align: right;
    width: 100%;
}

.loginBackImg {
  width: 80vw;
  height: 80vh;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.login-title {
  color: #00309F;
  font-size: 0.728vw;
  font-weight: 400;
  font-family: "PingFang SC";
}
.login-input {
  margin-top: 50px;
  box-sizing: border-box;
  border: 0px solid transparent;
  // border-bottom-width: 2px;
  // border-bottom-color:#012C9F;
  color: #012C9F;
  font-size: 1.1vw;
  width: 100%;
  height: 3vw;
  background-color: white;
  outline: none;
  border-radius: 0px;
  padding: 0 15px;
}
.login-input1 {
  margin-top: 30px;
  box-sizing: border-box;
  border: 0px solid transparent;
  // border-bottom-width: 2px;
  // border-bottom-color:#012C9F;
  color: #012C9F;
  font-size: 1.1vw;
  width: 100%;
  height: 3vw;
  background-color: white;
  outline: none;
  border-radius: 0px;
  padding: 0 15px;
}
.login-input2 {
  margin-top: 50px;
  // box-sizing: border-box;
  // border: 0px solid transparent;
  // border-bottom-width: 2px;
  // border-bottom-color:#012C9F;
  color: #DCDCDC;
  font-size: 20px;
  text-align: center;
  width: 100%;
  height: 3vw;
  background-color: #069F93;
  line-height:3vw;
  // outline: none;
  // border-radius: 0px;
  // padding: 0 15px;
}

.el-select-dropdown__item.selected {
  color: rgb(243, 152, 0);
  font-weight: 700;
}

.loginContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .loginBackImg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
  }

  .loginContentView {
    width: 70%;
    height: 70%;
    background: rgba(255, 255, 255);
    border-radius: 28px;
    position: relative;

    .loginContentContainer {
      width: 100%;
      position: absolute;
      z-index: 3;

      .content {
        display: flex;
        width: 90%;
        margin: 0 auto;

        .left {
          width: 50%;
          position: relative;

          .loginTitle {
            font-size: 4vh;
            margin-top: 10vh;
            text-align: center;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #002A38;
          }

          .loginInputView {
            margin: 5vh auto;
            width: 72%;
          }

          .loginBtn {
            width: 72%;
            height: 5vh;
            line-height: 5vh;
            text-align: center;
            margin: 6vh auto;
            background: #E74646;            
            border-radius: 10px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            font-size: 2vh;
            cursor: pointer;
          }

          .loginBtn:hover {
            opacity: 0.8;
          }

          .el-input__inner {
            border-radius: 10px !important;
            /* border: none; */
            height: 5vh;
            line-height: 5vh;
            font-size: 2vh;
            padding: 0 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #888888;
          }

          .el-input.is-active .el-input__inner, .el-input__inner:focus {
            border-color: rgb(243, 152, 0);
            outline: 0;
          }

          .el-input__icon {
            line-height: 5vh;
          }
        }

        .right {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          .logo {
            width: 60%;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }

    .loginContentImg {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
    }
  }
}
.loginLogoStyle{
    margin-top: 100px;
    //685 × 345
    width: 274px;
    height: 138px;

}
.loginView{
  width: 33vw;
  height: calc(100vh - 200px);
  margin-left: 33vw;
  // background-color: rebeccapurple;
}
.boxStyle{
  // box-sizing: border-box;
  // padding-left: 60px;
  // padding-right:60px;
  // padding-bottom: 60px;
  // padding-top: 60px;
  // margin-left: 10vw;
  margin-top: 50px;
  margin-bottom: 50px;
  // top: 10vh;
  width: 100vw;
  height: calc(100vh - 100px);
  background-color: white;
  background-image: url("../../assets/newBgImg.png");
  background-repeat: no-repeat;
    background-color: transparent;
    background-size: cover;
  float: left;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

</style>
